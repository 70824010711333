// Generated by Framer (8289486)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Gi07ywJvF: {hover: true, pressed: true}};

const cycleOrder = ["Gi07ywJvF"];

const variantClassNames = {Gi07ywJvF: "framer-v-1vlcgqb"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Gi07ywJvF", image: tdA4ZSxp3 = {src: new URL("assets/bLFziwfxXkjPs6A6eIAtry7ZJU.svg", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Gi07ywJvF", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PJXC7", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} background={{alt: "", fit: "stretch", intrinsicHeight: 128, intrinsicWidth: 269, pixelHeight: 128, pixelWidth: 269, src: new URL("assets/bLFziwfxXkjPs6A6eIAtry7ZJU.svg", import.meta.url).href}} className={cx("framer-1vlcgqb", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Gi07ywJvF"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"Gi07ywJvF-hover": {"data-framer-name": undefined}, "Gi07ywJvF-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "stretch", sizes: "80.5px", ...toResponsiveImage(tdA4ZSxp3)}} className={"framer-t9fl42"} layoutDependency={layoutDependency} layoutId={"Hv4FOtr4J"} transition={transition} {...addPropertyOverrides({"Gi07ywJvF-hover": {background: {alt: "", fit: "stretch", sizes: "115px", ...toResponsiveImage(tdA4ZSxp3)}}, "Gi07ywJvF-pressed": {background: {alt: "", fit: "stretch", sizes: "115px", ...toResponsiveImage(tdA4ZSxp3)}}}, baseVariant, gestureVariant)}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PJXC7 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PJXC7 .framer-1dt4wsx { display: block; }", ".framer-PJXC7 .framer-1vlcgqb { height: 69px; position: relative; width: 115px; }", ".framer-PJXC7 .framer-t9fl42 { flex: none; height: 58%; left: 0px; overflow: visible; position: absolute; top: calc(50.72463768115944% - 57.971014492753625% / 2); width: 70%; }", ".framer-PJXC7 .framer-v-1vlcgqb .framer-1vlcgqb { cursor: pointer; }", ".framer-PJXC7.framer-v-1vlcgqb.hover .framer-t9fl42 { width: 100%; }", ".framer-PJXC7.framer-v-1vlcgqb.pressed .framer-t9fl42 { left: calc(49.56521739130437% - 100% / 2); width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 69
 * @framerIntrinsicWidth 115
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CFCPNQveN":{"layout":["fixed","fixed"]},"gyn3aVGbv":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"tdA4ZSxp3":"image"}
 */
const FramerS_iqmd6Jr: React.ComponentType<Props> = withCSS(Component, css, "framer-PJXC7") as typeof Component;
export default FramerS_iqmd6Jr;

FramerS_iqmd6Jr.displayName = "squiggle";

FramerS_iqmd6Jr.defaultProps = {height: 69, width: 115};

addPropertyControls(FramerS_iqmd6Jr, {tdA4ZSxp3: {__defaultAssetReference: "data:framer/asset-reference,bLFziwfxXkjPs6A6eIAtry7ZJU.svg?originalFilename=Spiral+3+%28Stroke%29.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerS_iqmd6Jr, [])